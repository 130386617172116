import axios from 'axios'
import { MessageBox } from 'element-ui';
const isNewVersion = () => {
  let url = `/version.json`;
  axios.get(url,{headers: {'Cache-Control': 'no-cache'}}).then(res => {
    if (res.status === 200) {
      let vueVersion = res.data.version;
      let localVueVersion = localStorage.getItem('vueVersion');
      if (localVueVersion && localVueVersion != vueVersion) {
        // MessageBox.alert('检测到版本发生变化,请点击确认获取最新版本','版本变动',{
        //   confirmButtonText: '确定',
        //   callback: action => {
        //     localStorage.setItem('vueVersion', vueVersion);
        //     window.location.reload();
        //   }
        // })
        localStorage.setItem('vueVersion', vueVersion);
        window.location.reload();
        return;
      } else {
        localStorage.setItem('vueVersion', vueVersion);
      }
    }
  });
}
 
export default {
  isNewVersion
}
