import { Loading } from "element-ui";
export function debounce(func, wait) {
  let timeout;
  return function () {
    let context = this;
    let args = arguments;
    let later = () => {
      timeout = null;
      func.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export function createLoading(dom) {
  const option = {
    target: dom,
    lock: true,
    spinner: "custom-icon",
    background: "rgba(51, 51, 51, 0)",
    customClass: "custom-bg",
  };
  const loading = Loading.service(option);
  return loading;
}

export function createdTypeLoading(dom) {
  const option = {
    target: dom,
    lock: true,
    spinner: "custom-icon",
    background: "rgba(51, 51, 51, 0)",
    customClass: "custom-bg-type",
  };
  const loading = Loading.service(option);
  return loading;
}