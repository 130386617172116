<template>
  <div class="iframeDialog">
    <el-dialog
      title="预览"
      :modal="false"
      top="50px"
      :visible.sync="dialogVisible"
      width="60%"
    >
      <div class="iframe_edit">
        <iframe
          :src="iframSrc.url"
          frameborder="0"
          width="100%"
          height="100%"
        ></iframe>
        <!-- <embed
          :src="iframSrc.url"
          type="application/pdf"
          width="100%"
          height="100%"
        /> -->
        <!-- <object
          :data="iframSrc.url"
          type="application/pdf"
          width="100%"
          height="100%"
        ></object> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dowenClick">下 载</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { downloadUrl } from '@/api/lawsRegulations'
import FileSave from 'file-saver'
import qs from 'qs'
export default {
  props: {
    iframSrcs: {},
  },
  watch: {
    iframSrcs(v) {
      console.log(v)
      this.iframSrc = v
      this.dialogVisible = true
    },
  },
  created() {
    this.iframSrc = this.iframSrcs
    if (this.iframSrc.url) {
      this.dialogVisible = true
    }
  },
  data() {
    return {
      iframSrc: {},
      dialogVisible: false,
    }
  },
  methods: {
    async dowenClick() {
      //   console.log(this.iframSrc.dowenUrl)
      // if (this.iframSrc.isDent == 1) {
      // window.open(this.iframSrc.dowenUrl)
      // let data = await downloadUrl(
      //   qs.stringify({ url: this.iframSrc.dowenUrl })
      // )
      // var file = new Blob([data], {
      //   type: 'application/' + this.iframSrc.filename,
      // })
      // //直接下载而不预览
      // saveAs(file, 'aaa.txt')
      // let blob = new Blob([data], {
      //   type: 'application/octet-stream',
      // })
      // // console.log(blob, "blob");
      // var link = document.createElement('a')
      // link.download = this.iframSrc.filename
      // link.href = window.URL.createObjectURL(blob)
      // // console.log(link.href, "link.href======");
      // // link.download = name
      // link.click()
      // //释放内存
      // window.URL.revokeObjectURL(link.href)
      // } else {
      // }
      window.open(this.iframSrc.dowenUrl, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
.iframe_edit {
  width: 100%;
  height: 600px;
  overflow-y: auto;
}
</style>
