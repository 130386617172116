import request from 'request/request'

//获取法律法规菜单栏
export function libCategories(data) {
  return request({
    url: '/lar/libCategories',
    method: 'post',
    data,
  })
}
//获取法律法规列表
export function queryOfficicalWord(data) {
  return request({
    url: '/lar/queryOfficicalWord',
    method: 'post',
    data,
  })
}

//获取文章详情
export function openLibraryFile(data) {
  return request({
    url: '/lar/openLibraryFile',
    method: 'post',
    data,
  })
}
//获取收藏列表
export function querylCollectionWord(data) {
  return request({
    url: '/lar/querylCollectionWord',
    method: 'post',
    data,
  })
}
//获取收藏列表
export function collection(data) {
  return request({
    url: '/lar/collection',
    method: 'post',
    data,
  })
}
//添加汇编
export function addDocTempInfo(data) {
  return request({
    url: '/lar/addDocTempInfo',
    method: 'post',
    data,
  })
}
// 汇编数量
export function userDocTempCount() {
  return request({
    url: '/lar/userDocTempCount',
    method: 'post',
  })
}
// 汇编成册列表
export function assemblyList(data) {
  return request({
    url: '/lar/assemblyList',
    method: 'post',
    data,
  })
}
// 清空汇编
export function delDocTempInfo() {
  return request({
    url: '/lar/delDocTempInfo',
    method: 'post',
  })
}

// 汇编文章列表
export function queryAssembleWord(data) {
  return request({
    url: '/lar/queryAssembleWord',
    method: 'post',
    data,
  })
}
// 批量删除汇编选中文件
export function delAssembleByIds(data) {
  return request({
    url: '/lar/delAssembleByIds',
    method: 'post',
    data,
  })
}
// 批量删除汇编选中文件
export function writerMergeWord(data) {
  return request({
    url: '/lar/writerMergeWordToServe',
    method: 'post',
    data,
  })
}
// 批量删除汇编选中文件
export function checkAssembly(data) {
  return request({
    url: '/lar/checkAssembly',
    method: 'post',
    data,
  })
}

//获取文件路径
export function findTextUrl(params) {
  return crewordRequest({
    url: '/analysis/findTextUrl',
    method: 'get',
    params,
  })
}
//浏览记录
export function browsingHistory(params) {
  return request({
    url: '/lar/browsingHistory',
    method: 'post',
    params,
  })
}
//浏览列表
export function queryBrowsingHistory(data) {
  return request({
    url: '/lar/queryBrowsingHistory',
    method: 'post',
    data,
  })
}
//浏览
export function writingOpen(data) {
  return request({
    url: '/writing/open',
    method: 'post',
    data,
  })
}
//获取附件
export function wordAnnexList(data) {
  return request({
    url: '/lar/wordAnnexList',
    method: 'post',
    data,
  })
}
//下载附件
export function downloadUrl(data) {
  return request({
    url: '/writing/downloadUrl?url=' + data,
    method: 'get',
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/octet-stream',
    },
  })
}
