import axios from 'axios'
import { MessageBox } from 'element-ui'
import router from '../router/index'
let stateAxios = true
const myAxios = axios.create({
  baseURL:
    process.env.NODE_ENV == 'production'
      ? `${process.env.VUE_APP_BASE_API}/api`
      : '/api/api',
  timeout: 300000,
})

myAxios.interceptors.request.use(
  (config) => {
    let Token = localStorage.getItem('auth_token')
    if (Token) {
      config.headers.Authorization = Token ? `bearer ${Token}` : ''
    } else {
      config.headers.Authorization = process.env.VUE_APP_DEFAULT_TOKEN
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

myAxios.interceptors.response.use(
  (config) => {
    return config.data
  },
  async (error) => {
    console.log(error)
    if (!stateAxios) {
      return
    }
    if (error.response.status === 401 && stateAxios) {
      stateAxios = false
      MessageBox.alert(
        '登录过期或在其他电脑设备上登录，请重新登录',
        '登录状态异常',
        {
          confirmButtonText: '确定',
          callback: () => {
            router.push('/')
          },
        }
      )
      console.log(9999)
      return
    }
    return Promise.reject(error)
  }
)

export default myAxios
