export const myMixins = {
  methods: {
    checkBox(index1, index2, index3) {
      this.arr[index1].checked = false;
      this.arr[index2].checked = false;
      this.arr[index3].checked = false;

    },

    changeBox(checkedArr, emptyArr, newEmpty) {
      checkedArr.forEach((item) => {
        this.arr[item].checked = false;
      });

      emptyArr.forEach((item) => {
        this.arr[item].value = "";
      });

      if (newEmpty == 1) {
        this.newEmpty1 = emptyArr;
      } else {
        this.newEmpty2 = emptyArr;
      }
    },

    sliceDate(date, index1, index2, index3) {
      console.log(date, index1, index2, index3);
      const dateArr = date.split("-");
      this.arr[index1].value = dateArr[0];
      this.arr[index2].value = dateArr[1];
      this.arr[index3].value = dateArr[2];
    },
    sliceDateAll(date, index1, index2, index3, index4, index5) {
      console.log(date, index1, index2, index3, index4, index5);
      const hourArr = date.split(" ")[0].split("-");
      const minuteArr = date.split(" ")[1].split(":");
      this.arr[index1].value = hourArr[0];
      this.arr[index2].value = hourArr[1];
      this.arr[index3].value = hourArr[2];
      this.arr[index4].value = minuteArr[0];
      this.arr[index5].value = minuteArr[1];
    },
    formatArray(item) {
      let key = "";
      let label = "";
      let classfiy = "";
      let radio = [];
      let checkbox = [];
      let modelValue = "";
      let modelValue2 = "";
      let tips = ''
      let html = '';
      let thArr = '';
      let selectArr = [];
      let value = ""
      let fileIndex = ''
      if (item.classList.contains("T")) {
        //填空
        classfiy = 1;
        key = item.getAttribute("data-key");
        label = item.title;
      } else if (item.classList.contains("R")) {
        //填空类型单选
        key = item.getAttribute("data-key");
        classfiy = 2;
        label = item.title;
        let radioArr = item.querySelectorAll(".el-radio__label");
        radioArr = Array.from(radioArr).map((item) => {
          return {
            label: item.innerText,
          };
        });
        radio = radioArr;
      } else if (item.classList.contains("C")) {
        const keyArr = item.querySelectorAll(".el-checkbox");
        let checkboxArr = item.querySelectorAll(".el-checkbox__label");
        keyArr.forEach((item, index) => {
          checkboxArr[index].data_key = item.getAttribute("data-key");
        });
        label = item.title;
        checkboxArr = Array.from(checkboxArr).map((item) => {
          return {
            key: item.data_key,
            label: item.innerText,
            classfiy: 3,
          };
        });
        modelValue = false;
        checkbox = checkboxArr;
        //复选
        classfiy = 3;
      } else if (item.classList.contains("D")) {
        //  统一社会信用代码
        key = item.getAttribute("data-key");
        label = item.title;
        classfiy = 4;
      } else if (item.classList.contains("S")) {
        //日期
        key = item.getAttribute("data-key");
        label = item.title;
        classfiy = 5;
      } else if (item.classList.contains("F")) {
        //混合类型
        classfiy = 6;
        // console.log(item.innerHTML);
        label = item.title;
        html = item.innerHTML
      } else if (item.classList.contains("A")) {
        key = item.getAttribute("data-key");
        tips = item.dataset.title || '';
        label = item.title;

        //文本域
        classfiy = 7;
      } else if (item.classList.contains("Z")) {// 执法人员选
        console.log(item);
        key = item.getAttribute("data-key");
        label = item.title;
        classfiy = 8;
      } else if (item.classList.contains("M")) {// 带时分秒选择
        // console.log(item);
        key = item.getAttribute("data-key");
        label = item.title;
        classfiy = 9;
      } else if (item.classList.contains("v")) {// 小写为需要插入(45)当事人文本
        // console.log(item);
        if (this.fileIndex) {
          if (sessionStorage.getItem(`dangshiren${this.fileIndex}`)) {
            modelValue = sessionStorage.getItem(`dangshiren${this.fileIndex}`)
          }
        }
        key = item.getAttribute("data-key");
        tips = item.dataset.title || '';
        label = item.title;
        classfiy = 99;
        console.log();
        if (sessionStorage.getItem("dangshiren45") != "") {
          const index = item.dataset.index * 1
          this.arr[index].value = sessionStorage.getItem("dangshiren45")
        }
      } else if (item.classList.contains("l")) {// 小写为需要插入听证地点
        // console.log(item);
        if (this.fileIndex) {
          if (sessionStorage.getItem(`didian${this.fileIndex}`)) {
            modelValue = sessionStorage.getItem(`didian${this.fileIndex}`)
          }
        }
        key = item.getAttribute("data-key");
        label = item.title;
        classfiy = 15;
        if (sessionStorage.getItem("didian37") != "") {
          const index = item.dataset.index * 1
          this.arr[index].value = sessionStorage.getItem("didian37")
        }
      } else if (item.classList.contains("g")) {// 小写为需要插入文号
        // console.log(item);
        key = item.getAttribute("data-key");
        label = item.title;
        classfiy = 10;
        if (this.fileIndex) {
          if (sessionStorage.getItem(`wenhao${this.fileIndex}`)) {
            modelValue = sessionStorage.getItem(`wenhao${this.fileIndex}`)
          } else {
            if (sessionStorage.getItem('wenhao')) {
              modelValue = sessionStorage.getItem('wenhao')
            }
          }
        }
        if (sessionStorage.getItem(`wenhao${this.fileIndex}`)) {
          const index = item.dataset.index * 1
          this.arr[index].value = sessionStorage.getItem(`wenhao${this.fileIndex}`)
        } else{
          if (sessionStorage.getItem('wenhao')) {
            const index = item.dataset.index * 1
            this.arr[index].value = sessionStorage.getItem('wenhao')
          }
        }
      } else if (item.classList.contains("n")) {// 小写为需要插入(3)标题文本
        console.log(item);

        key = item.getAttribute("data-key");
        selectArr = item.title.split('/')
        classfiy = 97;
        label = item.dataset.title;
        modelValue = []
        if (sessionStorage.getItem("jianding3") != "") {

          const index = item.dataset.index * 1
          if (this.arr[index].value == null) {
            return ''
          }
          this.arr[index].value = sessionStorage.getItem("jianding3")
        }
        if (this.fileIndex) {
          if (sessionStorage.getItem(`jianding${this.fileIndex}`)) {
            modelValue = sessionStorage.getItem(`jianding${this.fileIndex}`)
          }
        }
        // 检验/检疫 split('/')
      } else if (item.classList.contains("b")) {// 小写为需要插入案由
        // console.log(item);
        if (this.fileIndex) {
          if (sessionStorage.getItem(`anyou${this.fileIndex}`)) {
            modelValue = sessionStorage.getItem(`anyou${this.fileIndex}`)
          }
        }
        key = item.getAttribute("data-key");
        tips = item.dataset.title || '';
        label = item.title;
        classfiy = 98;
        console.log();
        if (
          sessionStorage.getItem(`anyou${this.fileIndex}`) != ""
        ) {
          const index = item.dataset.index * 1
          this.arr[index].value =
            sessionStorage.getItem(`anyou${this.fileIndex}`)
        }
      } else if (item.classList.contains("f")) {// 小写为需要插入违法事实
        // console.log(item);
        if (this.fileIndex) {
          if (sessionStorage.getItem(`shishi${this.fileIndex}`)) {
            modelValue = sessionStorage.getItem(`shishi${this.fileIndex}`)
          }
        }
        key = item.getAttribute("data-key");
        tips = item.dataset.title || '';
        label = item.title;
        classfiy = 98;
        console.log();
        if (
          sessionStorage.getItem(`shishi${this.fileIndex}`) != ""
        ) {
          const index = item.dataset.index * 1
          this.arr[index].value =
            sessionStorage.getItem(`shishi${this.fileIndex}`)
        }
      } else if (item.classList.contains("h")) {// 小写为需要插入文本
        // console.log(item);
        if (this.fileIndex) {
          if (sessionStorage.getItem(`shishi${this.fileIndex}`)) {
            modelValue = sessionStorage.getItem(`shishi${this.fileIndex}`)
          }
        }
        key = item.getAttribute("data-key");
        tips = item.dataset.title || '';
        label = item.title;
        classfiy = 7;
        console.log();
        if (
          sessionStorage.getItem("shishsi43") != ""
        ) {
          const index = item.dataset.index * 1
          this.arr[index].value =
            sessionStorage.getItem("shishi43")
        }
      } else if (item.classList.contains("W")) {// 文号
        if (sessionStorage.getItem('wenhao')) {
          modelValue = sessionStorage.getItem('wenhao')
        }
        key = item.getAttribute("data-key");
        label = item.title;
        classfiy = 96;
        if (sessionStorage.getItem("wenhao") != "" && sessionStorage.getItem("wenhao") != null) {
          const index = item.dataset.index * 1
          this.arr[index].value = sessionStorage.getItem("wenhao")
        }
      } else if (item.classList.contains("w")) {// 小写为不需要关联文号
        key = item.getAttribute("data-key");
        label = item.title;
        classfiy = 10;
      } else if (item.classList.contains("J")) {//  动态表格
        // console.log(item);
        key = item.getAttribute("data-key") * 1;
        thArr = this.arr[key].thArr[1]
        classfiy = 11;
        value = item.checked
      } else if (item.classList.contains("H")) {//填空
        //单选
        key = item.getAttribute("data-key");
        let mainIndex = item.getAttribute("index");
        classfiy = 12;
        label = item.title;
        let radioArr = item.querySelectorAll(".el-radio__label");
        radioArr = Array.from(radioArr).map((item) => {
          return {
            label: item.innerText,
            mainKey: key,
            mainIndex: mainIndex * 1,
          };
        });
        Array.from(item.querySelectorAll(".el-radio__input > input")).forEach((item, index) => {
          radioArr[index].RadioKey = item.value
        })
        Array.from(item.querySelectorAll("label")).forEach((item, index) => {
          radioArr[index].OtherIndex = item.getAttribute('index')
        })

        radio = radioArr;
      } else if (item.classList.contains("X")) {//  下拉菜单
        // console.log(item);
        key = item.getAttribute("data-key");
        selectArr = item.title.split('/')
        classfiy = 13;
        label = item.dataset.title;
        modelValue = []
      } else if (item.classList.contains("B")) {//  下拉菜单
        // console.log(item);
        key = item.getAttribute("data-key");
        selectArr = item.title.split('/')
        classfiy = 14;
        label = item.dataset.title;
        modelValue = []
      } else if (item.classList.contains("E")) {//  地址选择
        if (this.fileIndex) {
          if (sessionStorage.getItem(`didiian${this.fileIndex}`)) {
            modelValue = sessionStorage.getItem(`didian${this.fileIndex}`)
          }
        }
        // console.log(item);
        key = item.getAttribute("data-key");

        classfiy = 15;
        label = item.title;
      } else if (item.classList.contains("U")) {//  法制审核意见
        // console.log(item);
        key = item.getAttribute("data-key");
        tips = item.dataset.title || '';
        // label = item.title;
        classfiy = 90;
        html = item.innerHTML
        modelValue = []
      }
      return {
        modelValue,
        modelValue2,
        key,
        label,
        classfiy,
        radio,
        checkbox,
        html,
        thArr,
        selectArr,
        tips,
      };
    },
    convertCurrency(money) {

      //汉字的数字
      var cnNums = new Array(
        "零",
        "壹",
        "贰",
        "叁",
        "肆",
        "伍",
        "陆",
        "柒",
        "捌",
        "玖"
      );
      //基本单位
      var cnIntRadice = new Array("", "拾", "佰", "仟");
      //对应整数部分扩展单位
      var cnIntUnits = new Array("", "万", "亿", "兆");
      //对应小数部分单位
      var cnDecUnits = new Array("角", "分", "毫", "厘");
      //整数金额时后面跟的字符
      var cnInteger = "整";
      //整型完以后的单位
      var cnIntLast = "元";
      //最大处理的数字
      var maxNum = 999999999999999.9999;
      //金额整数部分
      var integerNum;
      //金额小数部分
      var decimalNum;
      //输出的中文金额字符串
      var chineseStr = "";
      //分离金额后用的数组，预定义
      var parts;
      if (typeof (money * 1) == Number) {
        return "";
      }
      if (money == "") {
        return "";
      }
      money = parseFloat(money);
      if (money >= maxNum) {
        //超出最大处理数字
        return "";
      }
      if (money == 0) {
        chineseStr = cnNums[0]
        return chineseStr;
      }
      //转换为字符串
      money = money.toString();
      if (money.indexOf(".") == -1) {
        integerNum = money;
        decimalNum = "";
      } else {
        parts = money.split(".");
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
      }
      //获取整型部分转换
      if (parseInt(integerNum, 10) > 0) {
        var zeroCount = 0;
        var IntLen = integerNum.length;
        for (var i = 0; i < IntLen; i++) {
          var n = integerNum.substr(i, 1);
          var p = IntLen - i - 1;
          var q = p / 4;
          var m = p % 4;
          if (n == "0") {
            zeroCount++;
          } else {
            if (zeroCount > 0) {
              chineseStr += cnNums[0];
            }
            //归零
            zeroCount = 0;
            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
          }
          if (m == 0 && zeroCount < 4) {
            chineseStr += cnIntUnits[q];
          }
        }
        chineseStr
      }
      //小数部分
      if (decimalNum != "") {
        var decLen = decimalNum.length;
        for (var i = 0; i < decLen; i++) {
          var n = decimalNum.substr(i, 1);
          if (n != "0") {
            chineseStr += cnNums[Number(n)] + cnDecUnits[i];
          }
        }
      }
      if (chineseStr == "") {
        chineseStr += cnNums[0]
      } else if (decimalNum == "") {
        chineseStr
      }
      return chineseStr;
    },
  },

};
