import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user_info: {},
    onlineBasePath: 'http://fafs.antiplagiarize.com/',
    // 公共编辑器域名
    domainDame: 'https://ile.writemall.com/',
    flow: [],
    sidebarActive: 0,
  },
  getters: {},
  mutations: {
    setUserInfo(state, data) {
      state.user_info = data
    },
    ChangeSideBarId(state, payload) {
      state.sidebarActive = payload
    },
    setFlow(state, data) {
      state.flow = data
    },
  },
  actions: {},
  modules: {},
})
