import Vue from 'vue'
import VueRouter from 'vue-router'
import versionTood from '../utils/versionUpdate'

const originPush = VueRouter.prototype.push
const originReplace = VueRouter.prototype.replace

VueRouter.prototype.push = function (location, onComplete, onAbort) {
  if (!onComplete && !onAbort) {
    originPush.call(this, location, () => {})
  } else {
    originPush.call(this, location, onComplete, onAbort)
  }
}
VueRouter.prototype.replace = function (location, onComplete, onAbort) {
  if (!onComplete && !onAbort) {
    originReplace.call(this, location, () => {})
  } else {
    originReplace.call(this, location, onComplete, onAbort)
  }
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('views/login/login'),
  },
  {
    path: '/index',
    component: () => import('views/index/index'),
    redirect: '/writing',
    children: [
      {
        path: '/textCheck',
        component: () => import('views/textCheck/CheckingIndex'),
      },
      {
        path: '/writing',
        component: () => import('views/writing/generateArticleIndex'),
        meta: {
          data: 'true',
        },
      },
      {
        path: '/typesetting',
        component: () => import('views/typesetting/typesetting'),
      },
      {
        path: '/tools',
        component: () => import('views/tools/tools'),
      },
      //网盘
      {
        path: '/disk',
        component: () => import('views/disk/index'),
      },
      // 信息公开指南
      {
        path: '/Information',
        component: () => import('views/Information/index'),
      },
      //编辑器
      {
        path: '/editor',
        component: () => import('views/editor/index'),
      },
      // 法律法规库
      {
        path: '/lawsRegulations',
        name: 'lawsRegulations',
        component: () => import('views/lawsRegulations/lawsRegulations'),
      },
      // 法律风险防控
      {
        path: '/riskControl',
        name: 'riskControl',
        component: () => import('views/riskControl/riskControl'),
      },
      // 档案库
      {
        path: '/archives',
        name: 'archives',
        component: () => import('views/archives/archives'),
      },
      // word文档页面
      {
        path: '/wordfile',
        name: 'wordfile',
        component: () => import('views/wordfile/wordfileView'),
        redirect: '/wordfile/index1',
        children: [
          {
            path: '/wordfile/index1',
            name: 'index1',
            component: () => import('views/wordfile/index1'),
          },
          {
            path: '/wordfile/index2',
            name: 'index2',
            component: () => import('views/wordfile/index2'),
          },
          {
            path: '/wordfile/index3',
            name: 'index3',
            component: () => import('views/wordfile/index3'),
          },
          {
            path: '/wordfile/index4',
            name: 'index4',
            component: () => import('views/wordfile/index4'),
          },
          {
            path: '/wordfile/index68',
            name: 'index68',
            component: () => import('views/wordfile/index68'),
          },
          {
            path: '/wordfile/index69',
            name: 'index69',
            component: () => import('views/wordfile/index69'),
          },
          {
            path: '/wordfile/index70',
            name: 'index70',
            component: () => import('views/wordfile/index70'),
          },
          {
            path: '/wordfile/index71',
            name: 'index71',
            component: () => import('views/wordfile/index71'),
          },
          {
            path: '/wordfile/index72',
            name: 'index72',
            component: () => import('views/wordfile/index72'),
          },
          {
            path: '/wordfile/index73',
            name: 'index73',
            component: () => import('views/wordfile/index73'),
          },
          {
            path: '/wordfile/index74',
            name: 'index74',
            component: () => import('views/wordfile/index74'),
          },
          {
            path: '/wordfile/index75',
            name: 'index75',
            component: () => import('views/wordfile/index75'),
          },
          {
            path: '/wordfile/index76',
            name: 'index76',
            component: () => import('views/wordfile/index76'),
          },
          {
            path: '/wordfile/index77',
            name: 'index77',
            component: () => import('views/wordfile/index77'),
          },
          {
            path: '/wordfile/index78',
            name: 'index78',
            component: () => import('views/wordfile/index78'),
          },
          {
            path: '/wordfile/index79',
            name: 'index79',
            component: () => import('views/wordfile/index79'),
          },
          {
            path: '/wordfile/index80',
            name: 'index80',
            component: () => import('views/wordfile/index80'),
          },
          {
            path: '/wordfile/index81',
            name: 'index81',
            component: () => import('views/wordfile/index81'),
          },
          {
            path: '/wordfile/index82',
            name: 'index82',
            component: () => import('views/wordfile/index82'),
          },
          {
            path: '/wordfile/index83',
            name: 'index83',
            component: () => import('views/wordfile/index83'),
          },
          {
            path: '/wordfile/index84',
            name: 'index84',
            component: () => import('views/wordfile/index84'),
          },
          {
            path: '/wordfile/index85',
            name: 'index85',
            component: () => import('views/wordfile/index85'),
          },
          {
            path: '/wordfile/index86',
            name: 'index86',
            component: () => import('views/wordfile/index86'),
          },
          {
            path: '/wordfile/index87',
            name: 'index87',
            component: () => import('views/wordfile/index87'),
          },
          {
            path: '/wordfile/index88',
            name: 'index88',
            component: () => import('views/wordfile/index88'),
          },
          {
            path: '/wordfile/index89',
            name: 'index89',
            component: () => import('views/wordfile/index89'),
          },
          {
            path: '/wordfile/index90',
            name: 'index90',
            component: () => import('views/wordfile/index90'),
          },
          {
            path: '/wordfile/index91',
            name: 'index91',
            component: () => import('views/wordfile/index91'),
          },
          {
            path: '/wordfile/index5',
            name: 'index5',
            component: () => import('views/wordfile/index5'),
          },
          {
            path: '/wordfile/index6',
            name: 'index6',
            component: () => import('views/wordfile/index6'),
          },
          {
            path: '/wordfile/index7',
            name: 'index7',
            component: () => import('views/wordfile/index7'),
          },
          {
            path: '/wordfile/index8',
            name: 'index8',
            component: () => import('views/wordfile/index8'),
          },
          {
            path: '/wordfile/index9',
            name: 'index9',
            component: () => import('views/wordfile/index9'),
          },
          {
            path: '/wordfile/index10',
            name: 'index10',
            component: () => import('views/wordfile/index10'),
          },
          {
            path: '/wordfile/index11',
            name: 'index11',
            component: () => import('views/wordfile/index11'),
          },
          {
            path: '/wordfile/index12',
            name: 'index12',
            component: () => import('views/wordfile/index12'),
          },
          {
            path: '/wordfile/index13',
            name: 'index13',
            component: () => import('views/wordfile/index13'),
          },
          {
            path: '/wordfile/index14',
            name: 'index14',
            component: () => import('views/wordfile/index14'),
          },
          {
            path: '/wordfile/index15',
            name: 'index15',
            component: () => import('views/wordfile/index15'),
          },
          {
            path: '/wordfile/index16',
            name: 'index16',
            component: () => import('views/wordfile/index16'),
          },
          {
            path: '/wordfile/index17',
            name: 'index17',
            component: () => import('views/wordfile/index17'),
          },
          {
            path: '/wordfile/index18',
            name: 'index18',
            component: () => import('views/wordfile/index18'),
          },
          {
            path: '/wordfile/index19',
            name: 'index19',
            component: () => import('views/wordfile/index19'),
          },
          {
            path: '/wordfile/index20',
            name: 'index20',
            component: () => import('views/wordfile/index20'),
          },
          {
            path: '/wordfile/index21',
            name: 'index21',
            component: () => import('views/wordfile/index21'),
          },
          {
            path: '/wordfile/index22',
            name: 'index22',
            component: () => import('views/wordfile/index22'),
          },
          {
            path: '/wordfile/index23',
            name: 'index23',
            component: () => import('views/wordfile/index23'),
          },
          {
            path: '/wordfile/index24',
            name: 'index24',
            component: () => import('views/wordfile/index24'),
          },
          {
            path: '/wordfile/index25',
            name: 'index25',
            component: () => import('views/wordfile/index25'),
          },
          {
            path: '/wordfile/index26',
            name: 'index26',
            component: () => import('views/wordfile/index26'),
          },
          {
            path: '/wordfile/index27',
            name: 'index27',
            component: () => import('views/wordfile/index27'),
          },

          {
            path: '/wordfile/index28',
            name: 'index28',
            component: () => import('views/wordfile/index28'),
          },
          {
            path: '/wordfile/index29',
            name: 'index29',
            component: () => import('views/wordfile/index29'),
          },
          {
            path: '/wordfile/index30',
            name: 'index30',
            component: () => import('views/wordfile/index30'),
          },
          {
            path: '/wordfile/index31',
            name: 'index31',
            component: () => import('views/wordfile/index31'),
          },
          {
            path: '/wordfile/index32',
            name: 'index32',
            component: () => import('views/wordfile/index32'),
          },
          {
            path: '/wordfile/index33',
            name: 'index33',
            component: () => import('views/wordfile/index33'),
          },
          {
            path: '/wordfile/index34',
            name: 'index34',
            component: () => import('views/wordfile/index34'),
          },
          {
            path: '/wordfile/index35',
            name: 'index35',
            component: () => import('views/wordfile/index35'),
          },
          {
            path: '/wordfile/index36',
            name: 'index36',
            component: () => import('views/wordfile/index36'),
          },
          {
            path: '/wordfile/index37',
            name: 'index37',
            component: () => import('views/wordfile/index37'),
          },
          {
            path: '/wordfile/index38',
            name: 'index38',
            component: () => import('views/wordfile/index38'),
          },
          {
            path: '/wordfile/index39',
            name: 'index39',
            component: () => import('views/wordfile/index39'),
          },
          {
            path: '/wordfile/index40',
            name: 'index40',
            component: () => import('views/wordfile/index40'),
          },
          {
            path: '/wordfile/index41',
            name: 'index41',
            component: () => import('views/wordfile/index41'),
          },
          {
            path: '/wordfile/index42',
            name: 'index42',
            component: () => import('views/wordfile/index42'),
          },
          {
            path: '/wordfile/index43',
            name: 'index43',
            component: () => import('views/wordfile/index43'),
          },
          {
            path: '/wordfile/index44',
            name: 'index44',
            component: () => import('views/wordfile/index44'),
          },
          {
            path: '/wordfile/index45',
            name: 'index45',
            component: () => import('views/wordfile/index45'),
          },
          {
            path: '/wordfile/index46',
            name: 'index46',
            component: () => import('views/wordfile/index46'),
          },
          {
            path: '/wordfile/index47',
            name: 'index47',
            component: () => import('views/wordfile/index47'),
          },
          {
            path: '/wordfile/index48',
            name: 'index48',
            component: () => import('views/wordfile/index48'),
          },
          {
            path: '/wordfile/index49',
            name: 'index49',
            component: () => import('views/wordfile/index49'),
          },
          {
            path: '/wordfile/index50',
            name: 'index50',
            component: () => import('views/wordfile/index50'),
          },
          {
            path: '/wordfile/index51',
            name: 'index51',
            component: () => import('views/wordfile/index51'),
          },
          {
            path: '/wordfile/index52',
            name: 'index52',
            component: () => import('views/wordfile/index52'),
          },
          {
            path: '/wordfile/index53',
            name: 'index53',
            component: () => import('views/wordfile/index53'),
          },
          {
            path: '/wordfile/index54',
            name: 'index54',
            component: () => import('views/wordfile/index54'),
          },
          {
            path: '/wordfile/index55',
            name: 'index55',
            component: () => import('views/wordfile/index55'),
          },
          {
            path: '/wordfile/index56',
            name: 'index56',
            component: () => import('views/wordfile/index56'),
          },
          {
            path: '/wordfile/index57',
            name: 'index57',
            component: () => import('views/wordfile/index57'),
          },
          {
            path: '/wordfile/index58',
            name: 'index58',
            component: () => import('views/wordfile/index58'),
          },
          {
            path: '/wordfile/index59',
            name: 'index59',
            component: () => import('views/wordfile/index59'),
          },
          {
            path: '/wordfile/index60',
            name: 'index60',
            component: () => import('views/wordfile/index60'),
          },
          {
            path: '/wordfile/index61',
            name: 'index61',
            component: () => import('views/wordfile/index61'),
          },
          {
            path: '/wordfile/index62',
            name: 'index62',
            component: () => import('views/wordfile/index62'),
          },
          {
            path: '/wordfile/index63',
            name: 'index63',
            component: () => import('views/wordfile/index63'),
          },
          {
            path: '/wordfile/index64',
            name: 'index64',
            component: () => import('views/wordfile/index64'),
          },
          {
            path: '/wordfile/index65',
            name: 'index65',
            component: () => import('views/wordfile/index65'),
          },
          {
            path: '/wordfile/index66',
            name: 'index66',
            component: () => import('views/wordfile/index66'),
          },
          {
            path: '/wordfile/index67',
            name: 'index67',
            component: () => import('views/wordfile/index67'),
          },

          {
            path: '/wordfile/index91',
            name: 'index91',
            component: () => import('views/wordfile/index91'),
          },
        ],
      },
      // 投诉建议
      {
        path: '/complaints',
        name: 'complaints',
        component: () => import('views/complaints/complaints'),
      },
      // 系统管理
      {
        path: '/system',
        name: 'system',
        component: () => import('views/system/system'),
        redirect: '/userManagement',
        children: [
          // 用户管理
          {
            path: '/userManagement',
            name: 'userManagement',
            component: () => import('views/system/userManagement'),
          },
          // 档案管理
          {
            path: '/archivesManagement',
            name: 'archivesManagement',
            component: () => import('views/system/archivesManagement'),
          },

          //角色管理
          {
            path: '/roleAdmin',
            name: 'roleAdmin',
            component: () => import('views/system/roleAdmin'),
          },
          // 法律法规类型
          {
            path: '/lawRiskAdmin',
            name: 'lawRiskAdmin',
            component: () => import('views/system/lawRiskAdmin'),
          },
          // 法律法规内容
          {
            path: '/lawRiskContent',
            name: 'lawRiskContent',
            component: () => import('views/system/lawRiskContent'),
          },
          // 投诉建议类型
          {
            path: '/complaintsAdmin',
            name: 'complaintsAdmin',
            component: () => import('views/system/complaintsAdmin'),
          },
          // 执法人员管理
          {
            path: '/executorManagement',
            name: 'executorManagement',
            component: () => import('views/system/executorManagement'),
          },
          // 智能成文管理
          {
            path: '/generateManagement',
            name: 'generateManagement',
            component: () => import('views/system/generateManagement'),
          },
          // 成文推荐
          {
            path: '/generateRecommendManagement',
            name: 'generateRecommendManagement',
            component: () => import('views/system/generateRecommendManagement'),
          },
          // 成文字段简介
          {
            path: '/generateIntroduction',
            name: 'generateIntroduction',
            component: () => import('views/system/generateIntroduction'),
          },
          // 成文流程
          {
            path: '/processManagement',
            name: 'processManagement',
            component: () => import('views/system/processManagement'),
          },
          {
            path: '/processItem',
            name: 'processItem',
            component: () => import('views/system/processItem'),
          },
        ],
      },
    ],
  },
  // 流程图
  {
    path: '/flowChart',
    name: 'flowChart',
    component: () => import('views/system/flowChart'),
  },
  {
    path:'*',
    component: () => import('views/404')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

// // /* 添加路由守卫 */
router.beforeEach((to, from, next) => {
  versionTood.isNewVersion()
  next()
})

export default router
